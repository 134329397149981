import React, { useState, useEffect } from 'react';

import { call } from './services/api';


import NavBar from "./components/NavBar";
import Home from "./components/Home";
import Footer from "./components/Footer";






import ImageHasta from './assets/image/home/products/hasta.jpg';
import ImageSamatved from './assets/image/home/products/samatved.jpg';
import ImageShirove from './assets/image/home/products/shirove.jpg';
import ImageTrana from './assets/image/home/products/trana.jpg';

import LogOnly from './assets/image/logo/pload.png';




import ImageUser from './assets/image/home/user.svg';




const App = ( props ) => {
  



  const [links, setLinks] = useState([
    {
      title: 'Home',
      href: '#',
      id: 'home',
      hide: false
    },
    {
      title: 'About Us',
      href: '#',
      id: 'aboutus',
      hide: false
    },
    {
      title: 'Products',
      href: '#',
      id: 'products',
      hide: false
    },
    {
      title: 'Contact Us',
      href: '#',
      id: 'contact',
      hide: false
    },
    {
      title: 'Testimonials',
      href: "#",
      id: "testimonials",
      hide: false
    },
    {
      title: 'Success Stories',
      href: "#",
      id: "success",
      hide: false
    },
  ]);


  

  const [youtube, setYoutube] = useState([]);
  const [success, setSuccess] = useState([]);
  const [testimonial, setTestimonial] = useState([]);
  const [product, setProduct] = useState([]);


  const [isLoaded, setLoaded] = useState( false );




const getYoutube = async () => {
  console.log( "getYoutube");

  const data  = await call('get', 'youtube/all');
setYoutube(data); 
getProduct();
}
const getSuccess = async () => {
  console.log( "getSuccess");
    const data  = await call('get', 'success/all');
setSuccess(data); 

 
 completeSetup();
}
const getTestimonial = async () => {
  console.log( "getTestimonial");
    const data  = await call('get', 'testimonial/all');
setTestimonial(data); 



getSuccess();
}
const getProduct = async () => {
  console.log( "getProduct");

    const data  = await call('get', 'product/all');
setProduct(data); 

getTestimonial();
}

const completeSetup = () => {
  console.log( "loaded ");

    document.getElementsByTagName('body')[0].style.overflow = 'unset';
    setLoaded( true );
 

}

const componentMount = () => {
  console.log( "set ");
 try{

    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
  
  }  catch( err ){}
 
getYoutube();





};




  useEffect( componentMount, [] );



 

  return (
    <>
    {
        ! isLoaded ? <div className="div-loading-still cursor-wait"  title=" loading ..."> <div> <img src={LogOnly} /> </div> </div> : ""
      }

      <NavBar {...props} {...{ links }}/>
      
      <main>
        <Home {...props}    {...{ 

          youtube,
          success,
          testimonial,
          product
        } }     />
      </main>

      <Footer {...props}   {...{ product, links }} />
          

      

    </>


  );
}

export default App;