import axios from "axios";

let protocal = 'http:';

try {
    if (window.location.protocol == 'https:') {
        protocal = 'https:';
    }
} catch( err ){}

export const SERVER = `${protocal}//api.punarjeeva.tech`;
// export const SERVER = 'http://localhost/rest_api';

export const setToken = token => {
    if (token) { 
        axios.defaults.headers.common['punarjeeva-token'] = `${token}`;
    } else {
        delete axios.defaults.headers.common['punarjeeva-token'];
    }
};



export const call = async (method, path, data) => {

        const formData = new FormData();

        for ( let each in data) {
            formData.append(each, data[each]);
        }
 
        const response = await axios[method](`${SERVER}/punarjeeva/${path}`,formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
      }); 
      return response.data; 
}

export const pdfCall = async (method, path, data) => {
    const response = await axios[method](`${SERVER}/punarjeeva/${path}`, {
        responseType: 'blob'
    }, data);
    return response.data;
}


 


export default {
    call,
    setToken,
    pdfCall
};