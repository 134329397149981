import React, { useState, useEffect } from 'react';
import ImageLogoWhite from '../../assets/image/logo/logo-white.svg';

import { Link, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

import Loading from '../../assets/image/home/loading.gif';




const Component = ( props ) => {



 useEffect(() => { 
    if( ! props.yotube_key )
           props.actionShow( false, [] );
       else

    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        
    }, []);



    const [ content_status, showContent ] = useState( false); 

 const hideModelNow = () => {

    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    props.actionShow( false, [] );
 }





    return (
        <>
            


        <div className="punarjeev-youtube">


        <div className="modal fade bd-example-modal-lg d-block show" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"   aria-modal="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
            <div className="modal-header">
             {
                props.title ?    <h6 className="modal-title h5" id="myLargeModalLabel">{ props.title}</h6>: ""
            }

              
                <button type="button" className="close" onClick={ hideModelNow }>
                  <span aria-hidden="true" >×</span>
                </button>
              </div> 
           
             
              <div className="modal-body">
                <div className = { ` show-content p-3 ${ content_status && 'show' } `} >


                <button type="button" className="close" onClick={ e=> showContent( false ) }>
                  <span  className="text-white" ><i className="icofont-rounded-down"></i></span>
                </button>


                    <div className="text"> 
                        <p>{props.desc}</p>
                    </div>
                </div>
                <div className="videowrapper" style={{ backgroundImage: `url(${Loading})` }}> 
    <iframe width="560" height="349" src={ `https://www.youtube.com/embed/${props.yotube_key}?rel=0&hd=1` } frameborder="0" allowfullscreen></iframe>
</div>


              {
                props.desc ? <div className="text-center p-3">
                    <button type="button" className="btn btn-sm btn-info " onClick = {  e=> showContent( true ) }> read content</button>
              </div> : ""
              }
              </div>
            </div>
          </div>
        </div>


        </div>



        </>


    );
}

export default Component;