import React, { useState, useEffect } from 'react';
import Slider from "react-slick";

import Girl from '../../assets/image/home/smilng-girl.svg'

import HomeImage01 from '../../assets/image/home/home2icons1.svg';
import HomeImage02 from '../../assets/image/home/home2icons2.svg';
import HomeImage03 from '../../assets/image/home/home2icons3.svg';
import HomeImage04 from '../../assets/image/home/home2icons4.svg';

import BackgroundAll from '../../assets/image/home/background-all.svg';

import BackgroundShade01 from '../../assets/image/home/background-shade01.svg';

import ProductBackgroud from '../../assets/image/home/product-bakground-shade.svg';



import  Youtube from './Youtube.jsx';
import  Testimonial from './Testimonial.jsx';



import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Component = ( props ) => {

    const slickSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [ 
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    const slickImageSettings  = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false
         
    };

    const slickSuccessSettings = {
     dots: false,
        infinite: true,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
      cssEase: "linear", 
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,

      rtl: true,
        responsive: [ 
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },{
                breakpoint: 700,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }



    const [youtube, showYoutube] = useState( { show: false, data: {}} );
    const [testimonialMore, showTestimonialMore] = useState( { show: false, data: {}} );

    const showHideYotube = ( show, data = null ) => { 
        const parsed =  { yotube_key: data.youtube, desc: data.description, title: data.title };
 
            showYoutube( { show, data: parsed }); 
    }


    const showTestimonial = ( show, data = null ) => { 
        const { testimonial,
                user_image,
                user,
                type } = data;

  const parsed =  { testimonial, user_image, user, type } ;
 
            showTestimonialMore( { show, data: parsed }); 
  
    }




    const showProductYotube = ( show, data = null ) => { 

        const parsed =  { yotube_key: data.youtube, desc: data.description, title: `view ${ data.name } in action` };
 
            showYoutube( { show, data: parsed }); 
    }

    const ourProductMainBtn = () => {
        const data = props.youtube[0]; 


        const parsed =  { yotube_key: data.key, desc: data.description, title: data.title };
        
            showYoutube( { show: true, data: parsed }); 
    }



    useEffect(() => {
        // Update the document title using the browser API
        
    });



    return (
        <><div className="punarjeev-home element">
                <div className="container py-4">
                    <div className="row   align-items-center">
                        <div className="col-sm-12 col-md-5 py-5">
                            <div className="home-content w-100 d-flex justify-content-center">
                                <div className="main-content">
                                    <div className="heada">
                                        <p>
                                            Transforming lives for a better tomorrow
                                    </p>
                                        <p>
                                            with latest research in game therapy
                                    </p>

                                        <p>
                                            and sensor fusion for physical rehabilitation
                                    </p>

                                    </div>

                                    <div className="headb">
                                        <p>
                                            Our products change the face of rehabilitation 
                                    </p>

                                        <p>
                                             to provide faster recovery and rejuvenation
                                        </p>

                                    </div>

                                    <div className="headc">
                                        <a onClick={ e => ourProductMainBtn () } className="btn hover-effect ">Our Products</a>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-7">

                            <div className="home-image overflow-hidden">
                                <img src={Girl} />
                            </div>

                        </div>
                    </div>
                </div>
            </div>












            <div id="aboutus" name="aboutus" className="punarjeev-home01 py-5 " style={{ backgroundImage: `url(${BackgroundAll})` }}>
                <div className="container">
                    <div className="row d-flex align-items-center ">
                        <div className="col-md-7 col-sm-12 my-4">

                            <div className=" row d-flex shadebox-parent  align-items-center">
                                <div className="col-sm-12 col-md-4 shadebox flex-fill">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="card-h">
                                                <div className="avathar">
                                                <i className="icofont-game-console"></i>
                                                    {/*<img src={HomeImage04} /> */}
                                                </div>
                                            </div>
                                            <div className="card-b">
                                                <p>
                                                Game-therapy based platform with exercise and activities for enhancing the overall movement and those archives faster rehabilitation
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-4 p-0 d-flex flex-fill flex-column">
                                    <div className="shadebox flex-fill">

                                        <div className="card">
                                            <div className="card-body">
                                                <div className="card-h">
                                                    <div className="avathar">

                                                    <i className="icofont-group-students"></i> 
                                                       {/* <img src={HomeImage01} /> */}
                                                    </div>
                                                </div>
                                                <div className="card-b">
                                                    <p>
                                                        Punarjeeva products (Samatved, Hasth, Shirove, Trana, Samiksha and Parigyan)
                                                </p>
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                    <div className="shadebox flex-fill">



                                        <div className="card">
                                            <div className="card-body">
                                                <div className="card-h">
                                                    <div className="avathar">
                                                    <i className="icofont-focus"></i>
                                                       {/* <img src={HomeImage02} />*/}
                                                    </div>
                                                </div>
                                                <div className="card-b">
                                                    <p>Providing first of its kind vocational IT skill training using assistive technology for the specially abled society
                                                         </p>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-4  shadebox flex-fill">

                                    <div className="card">
                                        <div className="card-body">
                                            <div className="card-h">
                                                <div className="avathar">
                                                    
                                                    <i className="icofont-building-alt"></i>
                                                    {/* <<img src={HomeImage03} /> */}
                                                </div>
                                            </div>
                                            <div className="card-b">
                                                <p>
                                                    For Hospitals, Schools, Physical Rehabilitation Centres, Homes
                                                    </p>
                                            </div>
                                        </div>
                                    </div>



                                </div>
                            </div>

                        </div>
                        <div className="col-md-5 col-sm-12 my-3">

                            <div className="home2-vision ">
                                <h4 className="mb-4 font-subhead font-regular">Vision</h4>

                                <p>
                                    Reimagining the rehabilitation of society through gamified physiotherapy by exploiting the technological tentacles and providing opportunities that help them lead an independent and supreme life by stitching knowledge into the tasks.
                                </p>
                            </div>


                        </div>
                    </div>

                </div>
            </div>

 

















            <div id="products" name="products" className="punarjeev-products py-5      overflow-hidden   position-relative" >


   <div className="container">



                    <div className="row">
                        <div className="col-12">
                            <h3 className="mb-3 text-center font-head font-regular">
                                <span>Do you want to know</span> more about our Products?
                            </h3>
                            <p className="text-center">
                                Our first-of-its kind innovative products aims at improving the gross motor and fine motor skills. Our products removes the monotonous walls of conventional physiotherapy and creates an immersive and indulging therapy experience through latest technology.
                            </p>
                        </div>
                    </div>

                </div>



                {
                    props.product.map((each, index) => {
                        return  <div id={`product_${each.id}`}  name={`product_${each.id}`} key={index} className={` each-product-base position-relative ${index % 2 == 1 && 'invert'} `} >
                                <img
                                    className={` product-background ${index % 2 == 1 && 'invert'} `}
                                    src={ProductBackgroud} />
                                <div className="container position-relative">


                                    <div className="row d-flex my-auto align-items-center ">
                                        <div className={` col-md-5 col-sm-12 my-5 ${index % 2 == 1 && ' order-1'}  `}    >
                                            <div className="content">
                                                <h3 className="font-subhead mb-4">{each.name}</h3>

                                                {
                                                    [each.title].map(eachIn =>
                                                        <div key={ eachIn }>
                                                            <p className="font-msubhead">
                                                                {eachIn}
                                                            </p>

                                                        </div>)
                                                }


                                                <ul className="product-props mt-4">

                                                    {
                                                        typeof each.feature == "object" ?
                                                            each.feature.map(eachIn => {

                                                                return <li key= { eachIn } className="d-flex">
                                                                    <span>
                                                                        <div className="list-circle"></div>
                                                                    </span>
                                                                    <span>
                                                                        <p className="font-regular">
                                                                            {eachIn}
                                                                        </p>
                                                                    </span>
                                                                </li>

                                                            })

                                                            :
                                                            <></>
                                                    }
                                                </ul>
                                            </div>

                                        </div>
                                        <div className="col-md-7 col-sm-12 my-5 " >



                                            <div style={{ width: '95%' }} className={ ` proudct-image d-block  ${index % 2 == 0 ? ' ml-auto ' : ' mr-auto ' }  ${ each.image.length > 1 && " move-cursor " }` } >


                                            <Slider {...slickImageSettings}>
                                                 

                                                {
                                                    each.image.map( ( each, index) => {

                                                        return <img src={ each } key={each} />
                                                    })
                                                }
                                            

                                            </Slider>
                                                

                                            </div>



                                        </div>
                                    </div>

                                    <div className="text-center">
<div className="headc">
                                       <a  onClick={ e => showProductYotube( true, each ) }   className="btn hover-effect cursor-pointer text-uppercase ">view {each.name } in action</a>
                                    </div>

                                    </div>


                                </div>

                            </div> 

                    })
                }


            </div>

























            <div id="contact" name="contact"  className="punarjeev-contact-us py-5   d-flex   overflow-hidden   position-relative"

                style={{ backgroundImage: `url(${BackgroundShade01})` }}

            >



                <div className="container align-self-center">



                    <div className="row d-flex">
                        <div className="col-12 align-self-center">
                            <h3 className="mb-3 text-center font-head font-regular">
                               Are you interested in a Demo?
                            </h3>
                            <p className="text-center">
                                Meet one of our Punarjeevanist to get more insights into our products.
                            </p>




                            <div className="py-3 contact-us-body">

                                <div className="row">
                                    <div className="col-sm-12 col-md-4 text-right mt-3">
                                        <a href={ `mailto:support@punarjeeva.tech` } className="btn hover-effect w-100 overflow-hidden" target="_top">
                                            <span>
                                                <i className="icofont-email"></i>
                                            </span>
                                            <span>
                                            support@punarjeeva.tech     
                                            </span>
                                        </a>
                                    </div>
                                    <div className="col-sm-12 col-md-4 mt-3">
                                        <a href={ `https://api.whatsapp.com/send?phone=917306132306` } className="btn hover-effect w-100" target="_top">
                                            <span>
                                            <i className="icofont-brand-whatsapp"></i> 
                                            </span>
                                            <span>
                                                +91 7306132306
                                            </span>
                                        </a>
                                    </div>
                                    <div className="col-sm-12 col-md-4 mt-3">
                                        <a href={ `tel:+917306132306` } className="btn hover-effect w-100" target="_top">
                                            <span> 
                                                <i className="icofont-phone"></i>
                                            </span>
                                            <span>
                                                +91 7306132306
                                            </span>
                                        </a>
                                    </div>
                                </div>

                            </div>



                        </div>
                    </div>

                </div>



                </div>
 

















            <div id="testimonials" name="testimonials"  className="punarjeev-testimonials pt-5 pb-2   d-flex   overflow-hidden   position-relative">



                <div className="container align-self-center">



                    <div className="row d-flex">
                        <div className="col-12 align-self-center">
                            <h3 className="mb-5 text-center font-head font-regular">
                                   <span>Clients</span>  Testimonials                      </h3>
                           




                            <div className="py-3 testimonials-body">

                                <div className="row">
                                    <div className="col mt-3">


                                        <Slider {...slickSettings}>
                                             

                                            {
                                                props.testimonial.map( ( each, index) => {

                                                    return <div  key={index} className="px-2 pb-5">
                                                        <div className="card position-relative">

                                                            <div className="card-body">
                                                                <p className="content">
                                                                    <i className="icofont-quote-left"></i>
                                                                    {
                                                                        typeof each.testimonial != "undefined" ?
                                                                        <>
                                                                            { `${ each.testimonial.substr(0, 280 ) }${each.testimonial.length > 280 ? '...' : ''}` }
                                                                            {each.testimonial.length > 280 ? <a  className="text-link" onClick={  e => showTestimonial( true, each ) } > read more</a> : ''}
                                                                            </>

                                                                        : ""
                                                                    }
                                                                    <i className="icofont-quote-right"></i>
                                                                </p>
                                                            </div>
                                                            <div className="card-footer user d-flex align-items-start d-flex flex-row">
                                                                <img src={each.user_image} />
                                                                
                                                                <div>
                                                                <div className="position -absolute">
                                                                <span className="name font-msubhead text-nowrap  w-100 d-block">{each.user}</span>
                                                                <span   title={each.type} >{each.type}</span>

                                                                </div>

                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                })
                                            }
 

                                        </Slider>
                                         
                                    </div>
                                   
                                </div>

                            </div>



                        </div>
                    </div>

                </div>



            </div>















            <div id="success" name="success"  style={{ marginBottom: '-6px' }} className={ ` punarjeev-success p-0   overflow-hidden   position-relative ${ props.success.length < 1 ? " d-none " : " d-flex   "}` }>



                <div className="container-fluid align-self-center">



               
                    <div className="row d-flex">
                        <div className="col-12 align-self-center">
                            <h3 className="mb-5 text-center font-head font-regular">
                                   <span>Success</span>  Stories                      </h3>
                           


                            <div className="p-0 testimonials-body">

                                <div className="row">
                                    <div className="col px-0 mt-3">


                                        <Slider {...slickSuccessSettings}>
                                             

                                            {
                                                props.success.map( ( each, index) => {

                                                    return  <div key={index} className="success success_stories">
                                                      <div className="success__peach">
                                                        <img 
                                                        src={ `http://img.youtube.com/vi/${ each.youtube }/maxresdefault.jpg`  } 

                                                        className="success__image" alt="Preview"/>
                                                        <span className="success__label text-capitalize text-white"> 


                                                        <p>{ each.title }</p>


                                        <a  onClick={ e => showHideYotube( true, each ) }  className="btn cursor-pointer cust-view">view now</a>

                                                        </span>
                                                      </div>
                                                    </div>
 
                                                })
                                            }
            

                                        </Slider>
                                         
                                    </div>
                                   
                                </div>

                            </div>



                        </div>
                    </div>

                </div>



            </div>




{
    youtube.show ? <Youtube { ...youtube.data } actionShow = {showHideYotube} /> : ""
}

{
    testimonialMore.show ? <Testimonial { ...testimonialMore.data } actionShow = {showTestimonialMore} /> : ""
}




        </>


    );
}

export default Component;