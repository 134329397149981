import React, { useState, useEffect } from 'react';
import ImageLogoWhite from '../../assets/image/logo/logo-white.svg';

import { Link, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

import Loading from '../../assets/image/home/loading.gif';




const Component = ( props ) => {



 useEffect(() => { 
    if( ! props.testimonial )
           props.actionShow( false, [] );
       else

  // const parsed =  { testimonial, user_image, user, type } ;
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        
    }, []);



    const [ content_status, showContent ] = useState( false); 

 const hideModelNow = () => {

    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    props.actionShow( false, [] );
 }





    return (
        <>
            


        <div className="punarjeev-youtube">


        <div className="modal fade bd-example-modal-lg d-block show" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"   aria-modal="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
            <div className="modal-header">
            <div>

 <img width="70" height="70" className="mr-3 rounded-circle my-auto" src={props.user_image} />
                                                                
            </div> 
             <div>

                 {
                    props.user ?    <h6 className="modal-title h5" id="myLargeModalLabel">{ props.user}</h6>: ""
                }
                <p className="text-info mb-0 "> {props.type}</p>
                </div>

              
                <button type="button" className="close" onClick={ hideModelNow }>
                  <span aria-hidden="true" >×</span>
                </button>
              </div> 
           
             
              <div className="modal-body">
               <div className="text"> 
                        <p>{props.testimonial}</p>
                    </div>
                
              </div>
            </div>
          </div>
        </div>


        </div>



        </>


    );
}

export default Component;