import React, { useState, useEffect, useRef } from 'react';

import { Link,  Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'



import Logo from '../../assets/image/logo/logo.png'


const Component = ( props ) => {

 
    const [showMenu, toggleMenuM] = useState( false );
    let scrollTop = 0;
    let navStyle = { top : 0 }
    const nav_ba = useRef();
  

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        Events.scrollEvent.register('begin', function () {
            // console.log("begin", arguments);
        });

        Events.scrollEvent.register('end', function () {
            // console.log("end", arguments);
        });



       return () => {
            window.removeEventListener('scroll', () => { })
            Events.scrollEvent.remove('begin');
            Events.scrollEvent.remove('end');

            
        }
    }, []);





    const scrollToTop = () => {
        scroll.scrollToTop();
    }
    const scrollTo = () => {
        scroller.scrollTo('scroll-to-element', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
    }
 


    const handleScroll = e => {
        const old_offset = scrollTop;
        const new_offset = window.pageYOffset;
       

        const tmp_diff = new_offset - old_offset;
        if( tmp_diff > 0) 
            toggleMenuM( false );
        navStyle = { top: Math.min(0, Math.max(-90, navStyle.top + (-1 * tmp_diff) ) ) };

        nav_ba.current.style.top = `${navStyle.top}px`;
        scrollTop = new_offset;

    }


    const toggleMenu = () =>  {

        toggleMenuM( ! showMenu);
    }



    return (
        <>
            <div id="home" name="home"  className="nav-parent" style={{ height: 90 }}>
           
                <nav ref={nav_ba} id="nav_ba" className="site-header navbar navbar-expand-lg " style={{ top: 0 }}   >
                    <div className="container">
                        <a className="navbar-brand" href="#">
                            <img src={Logo} /> 
                        </a>
                        <button className="navbar-toggler" onClick={ e => toggleMenu() } type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="icofont-navigation-menu"></span>
                        </button>
                        <div className= { `collapse navbar-collapse ${ showMenu && "show" }` }  >
                            <ul className="navbar-nav ml-auto">
                                {
                                    props.links.map((each, index) =>
                                        each.hide ? "" :
                                        <li key={index} className="nav-item">
                                            <Link 
                                            activeClass="active" 
                                            className="nav-link cursor-pointer font-regular" 
                                            to={each.id} 
                                            spy={true} 
                                            smooth={true} 
                                            duration={500}>{each.title}</Link>
                                            
                                             
                                        </li>
                                    )
                                   
                                }
                                <li class="nav-item">
                                    <a class="nav-link cursor-pointer font-regular" href="/reviews" smooth="true" duration="500" target="_blank">Reviews</a>
                                </li>

                            </ul>

                        </div>
                    </div>
                </nav>

           </div>
        </>


    );
}

export default Component;