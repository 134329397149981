import React, { useState, useEffect } from 'react';
import ImageLogoWhite from '../../assets/image/logo/logo-white.svg';

import { Link, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'





const Component = ( props ) => {






    return (
        <>


                    <footer className=" pt-5">
                        <div className="container pb-3">
                            
                            
                            <div className="row">
                                <div className="col-12 col-md">
                                    <div className="image-logo">
                                        <img className="float-left" src={ImageLogoWhite} />
                                        
                                        <small className="w-100 text-left d-block mb-2 text-muted">
                                        

                                            <span className="mr-2">
                                                <i className="icofont-email"></i>
                                            </span>
                                            <span>
                                                support@punarjeeva.tech                                                    
                                            </span>  
                                        </small>
                                            <small className="w-100 text-left d-block mb-2 text-muted">
                                          

                                            <span className="mr-2">
                                            <i className="icofont-brand-whatsapp"></i> / 
                                                <i className="icofont-phone"></i>
                                            </span>
                                            <span>
                                                +91 7306132306
                                                    </span>

                                        </small>

                                    </div>
                                    </div>
                                <div className="col-12 col-md">
                                    <h5>Top Links</h5>
                                    <ul className="list-unstyled text-small">
                                        {
                                             props.links.map((each, index) =>
                                        <li key={index} >
                                        
                                        

                                            <Link
                                                activeClass="active"
                                                className="text-muted cursor-pointer"
                                                to={each.id}
                                                spy={true}
                                                smooth={true}
                                                duration={100}>{each.title}</Link>
 
                                        
                                        </li>
                                            
                                    )
                                        }
                                        <li key={6}>
                                            <a
                                            activeClass="active"
                                                className="text-muted cursor-pointer"
                                                href="/reviews"
                                               >Reviews</a>
                                        </li>
                                        <li key={7}>
                                            <a
                                            activeClass="active"
                                                className="text-muted cursor-pointer"
                                                href="/accessibility"
                                               >Accessibility Guidelines</a>
                                        </li>
                                    
                                        
                                    </ul>
                                </div>
                                <div className="col-12 col-md">
                                    <h5>Our Products</h5>
                                    <ul className="list-unstyled text-small">
                                        {
                                            props.product.map( (each, index ) =>
                                        <li key={index}>
<Link
                                                activeClass="active"
                                                className="text-muted cursor-pointer text-capitalize"
                                                to={`product_${each.id}`}
                                                spy={true}
                                                smooth={true}
                                                duration={100}>{each.name}</Link> </li>
                                    )
                                        }
                                        
                                    
                                    </ul>
                                </div>
                                
                            </div>
                        </div>

                        <div className="container-fluid bttm ">
                        <div className="container py-3    d-flex justify-content-between align-items-center">
                                        <p className="text-muted">
        © 2020 PunarjeevaTechnology Solutions LLP 
                                        </p>
                            </div>
                        </div>
                    </footer>


        </>


    );
}

export default Component;